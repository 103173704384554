<template>
  <div :class="{ active: !secondaryMarket || !isFundRaising }" class="tab-pane fade show" id="common"
       role="tabpanel" aria-labelledby="common-tab">
    <div class="block-common-info">
      <div class="h4">{{ project.general.name }}</div>
      <div v-if="project.general.siteUri">
        <i class="bi-link-45deg"></i>
        <a :href="project.general.siteUri" target="_blank">
          {{ siteUrlHost }}
        </a>
      </div>
      <div class="h6">Описание</div>
      <div v-html="project.general.projectFeatures"></div>
      <div class="h6">Цель займа</div>
      <div v-html="project.general.loanPurpose"></div>
      <div class="h6">Результаты проверки</div>
      <div v-if="project.checks" class="check-list">
        <div v-for="(check, key) in checksList" :key="key" class="check">
          <div class="check__name">{{ check.name }}</div>
          <div>{{ check.value }}</div>
          <div>
            <a v-for="(link, index) in check.links" :key="index" :href="link" target="_blank">ссылка, </a>
          </div>
        </div>
      </div>
      <div v-if="project.general.companyFiles.length">
        <div class="h6">Вложения</div>
        <div v-for="document in project.general.companyFiles" :key="document.id" class="mt-2">
          <a :href="document.url" target="_blank">
            <i class="bi-file-earmark-arrow-down"></i>
            {{ document.name }}
          </a>
        </div>
      </div>
      <div v-if="project.general.companyLinks">
        <div class="h6">Ссылки</div>
        <div v-html="project.general.companyLinks"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailedProjectCommonInfo",
  props: {
    project: {
      type: Object,
      required: true
    },
    secondaryMarket: {
      type: Boolean,
      required: true
    },
    isFundRaising: {
      type: Boolean,
      required: true
    },
    siteUrlHost: {
      type: String,
      required: true
    }
  },
  computed: {
    checksList() {
      const checks = [];
      const checkTypes = [
        { key: 'checkBankruptcy', label: 'Проверка на банкротство' },
        { key: 'checkCourt', label: 'Суды' },
        { key: 'checkEnforcementProceedings', label: 'Исполнительное производство' },
        { key: 'checkRegister', label: 'Реестр недобросовестных поставщиков' },
        { key: 'checkAuction', label: 'Участие в торгах' }
      ];

      checkTypes.forEach(({ key, label }) => {
        if (this.project.checks[key] || this.project.checks[key + 'Link']) {
          checks.push({
            name: label,
            value: this.project.checks[key],
            links: [this.project.checks[key + 'Link']].filter(Boolean)
          });
        }
      });

      return checks.concat(this.project.checks.checks || []);
    }
  }
};
</script>

<style lang="scss" scoped>
.block-common-info {
  .check {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;

    &__name {
      color: #6c757d;
      font-weight: normal;
    }
  }
  .check-list {
    margin-top: 1rem;
  }
}
</style>
