<template>
  <div>
    <label v-if='label' class="text-muted">за период</label>
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="input-group align-items-center">
          <div class="input-group-append">
            <label class="input-group-text">с</label>
          </div>
          <input type="text" class="form-control text-center small-padding-input"
                 v-model="start.day" placeholder="ДД" maxlength="2"
                 @input="handleInput('start', 'day', $event)"
                 @keydown="handleBackspace('start', 'day', $event)"
                 ref="startDay">
          <input type="text" class="form-control text-center small-padding-input"
                 v-model="start.month" placeholder="ММ" maxlength="2"
                 @input="handleInput('start', 'month', $event)"
                 @keydown="handleBackspace('start', 'month', $event)"
                 ref="startMonth">
          <input type="text" class="form-control text-center"
                 v-model="start.year" placeholder="ГГГГ" maxlength="4"
                 @input="handleInput('start', 'year', $event)"
                 @keydown="handleBackspace('start', 'year', $event)"
                 ref="startYear">
        </div>
        <small v-if="errors.start" class="text-danger w-100 mt-1">Неверный формат или диапазон даты.</small>
      </div>

      <div class="col-12 col-sm-6 mt-1 mt-sm-0">
        <div class="input-group align-items-center">
          <div class="input-group-append">
            <label class="input-group-text">по</label>
          </div>
          <input type="text" class="form-control text-center small-padding-input"
                 v-model="end.day" placeholder="ДД" maxlength="2"
                 @input="handleInput('end', 'day', $event)"
                 @keydown="handleBackspace('end', 'day', $event)"
                 ref="endDay">
          <input type="text" class="form-control text-center small-padding-input"
                 v-model="end.month" placeholder="ММ" maxlength="2"
                 @input="handleInput('end', 'month', $event)"
                 @keydown="handleBackspace('end', 'month', $event)"
                 ref="endMonth">
          <input type="text" class="form-control text-center"
                 v-model="end.year" placeholder="ГГГГ" maxlength="4"
                 @input="handleInput('end', 'year', $event)"
                 @keydown="handleBackspace('end', 'year', $event)"
                 ref="endYear">
        </div>
        <small v-if="errors.end" class="text-danger w-100 mt-1">Неверный формат или диапазон даты.</small>
        <small v-if="errors.range" class="text-danger w-100 mt-1">
          Дата начала не может быть позже даты окончания.
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "CalendarRange",
  props: {
    initialStart: Object,
    initialEnd: Object,
    label: Boolean
  },
  data() {
    const defaultStart = moment().subtract(1, 'year').format("DD.MM.YYYY").split('.');
    const defaultEnd = moment().format("DD.MM.YYYY").split('.');

    return {
      start: this.initialStart || {
        day: defaultStart[0],
        month: defaultStart[1],
        year: defaultStart[2]
      },
      end: this.initialEnd || {
        day: defaultEnd[0],
        month: defaultEnd[1],
        year: defaultEnd[2]
      },
      maxDate: moment().format("YYYY-MM-DD"),
      errors: {
        start: false,
        end: false,
        range: false
      }
    };
  },
  watch: {
    start: {
      handler() {
        this.validateDate('start');
        this.checkDateRange();
        this.emitDates();
      },
      deep: true
    },
    end: {
      handler() {
        this.validateDate('end');
        this.checkDateRange();
        this.emitDates();
      },
      deep: true
    }
  },
  methods: {
    handleInput(field, type, event) {
      const nextField = { day: 'Month', month: 'Year' }[type];
      const isPaste = event.inputType === 'insertFromPaste';
      if (!isPaste && event.target.value.length === event.target.maxLength && nextField) {
        this.$refs[`${field}${nextField}`].focus();
      }

      this.validateDate(field);
    },
    handleBackspace(field, type, event) {
      const input = event.target;
      const isBackspace = event.key === 'Backspace';
      if (isBackspace && input.selectionStart === 0 && input.value === '') {
        const prevField = { month: 'Day', year: 'Month' }[type];
        if (prevField) {
          this.$refs[`${field}${prevField}`].focus();
        }
      }
    },
    validateDate(field) {
      let { day, month, year } = this[field];
      day = day.padStart(2, "0");
      month = month.padStart(2, "0");

      const isValid =
          day >= "01" && day <= "31" &&
          month >= "01" && month <= "12" &&
          year.length === 4;

      if (!isValid) {
        this.errors[field] = true;
        return;
      }

      const formatted = `${year}-${month}-${day}`;
      this.errors[field] = !(formatted >= "2000-01-01" && formatted <= this.maxDate);
    },
    emitDates() {
      const valid = !this.errors.start && !this.errors.end && !this.errors.range;
      this.$emit("update", {
        start: this.start,
        end: this.end,
        valid
      });
    },
    checkDateRange() {
      const startValid = !this.errors.start;
      const endValid = !this.errors.end;

      const startDate = moment(`${this.start.year}-${this.start.month.padStart(2, "0")}-${this.start.day.padStart(2, "0")}`);
      const endDate = moment(`${this.end.year}-${this.end.month.padStart(2, "0")}-${this.end.day.padStart(2, "0")}`);

      this.errors.range = startValid && endValid && startDate.isAfter(endDate);
    }
  }
};
</script>

<style scoped>
.small-padding-input {
  padding: 0 !important;
}
</style>
