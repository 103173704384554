<template>
  <div v-if="financials" class="tab-pane fade" id="financials" role="tabpanel" aria-labelledby="financials-tab">
    <div class="block-financials">
      <div class="row">
        <div class="col-12 col-md-6">
          <dl>
            <dt>Валюта баланса</dt>
            <dd>
              <money :money="financials.coreAssets" />
            </dd>
            <dt>Собственный капитал</dt>
            <dd>
              <money :money="financials.equityIncluding" />
            </dd>
            <dt>Объем кредитных обязательств</dt>
            <dd>
              <money :money="financials.amountOfCreditObligations" />
            </dd>
          </dl>
        </div>
        <div class="col-12 col-md-6">
          <dl>
            <dt>Выручка</dt>
            <dd>
              <money :money="financials.revenue" />
            </dd>
            <dt>Чистая прибыль</dt>
            <dd>
              <money :money="financials.netProfit" />
            </dd>
            <dt>Количество сотрудников</dt>
            <dd>{{ financials.numberOfEmployeesAtTheMoment }}</dd>
          </dl>
        </div>
      </div>
      <div class="h6">Комментарии</div>
      <div v-html="financials.comment"></div>
    </div>
  </div>
</template>

<script>
import Money from "@/components/common/components/Money.vue";

export default {
  name: "DetailedProjectFinancials",
  components: {Money},
  props: {
    financials: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.block-financials {
  dl {
    dt {
      flex: 0 0 65%;
      max-width: 65%;
    }
    dd {
      flex: 0 0 35%;
    }
  }
}
</style>
