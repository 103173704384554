<template>
  <div class="col-12 col-lg-6 mt-3 mt-lg-0">
    <div class="block-general">
      <div class="row">
        <div class="col-12 col-md-9">
          <div class="h2">{{ project.general.name }}</div>
          <div class="card-subtitle text-muted">{{ project.general.industry }}</div>
        </div>
        <div class="col-12 col-md-3 mt-3 mt-md-0">
          <span class="h5 text-success">{{ project.general.mortgageRank }}</span>
          <public-link to="/page/policy-risks"
                       class="small text-muted d-md-block ml-1 ml-md-0 link-mortgage-rank"
                       target="_blank"
          >
            Рейтинг
          </public-link>
          <date-format :date="project.loan.maturityDate"
                       class="text-muted mt-md-2 d-md-block float-right float-md-none"/>
        </div>
      </div>
      <hr>
      <dl>
        <dt>Ставка:</dt>
        <dd class="text-info" v-if="project.loan.interestRateType === InterestRateType.VARIABLE">
          {{ project.loan.interestRate ? (Math.round(project.loan.interestRate * 10) / 10) + '%' : '' }}
          <CTooltip text="Прогноз доходности от продажи объекта"
                    :text-position=TooltipTextPosition.BOTTOM_RIGHT></CTooltip>
        </dd>
        <dd v-else>
          {{ project.loan.interestRate ? (Math.round(project.loan.interestRate * 10) / 10) + '%' : '' }}
        </dd>
        <dt>Срок:</dt>
        <dd>{{ project.loan.initialTerm }} мес.</dd>
        <dt>Тип объекта:</dt>
        <dd>{{ project.loan.objectType }}</dd>
        <dt>LTV:</dt>
        <dd>
          {{ project.loan.ltv ? (Math.round(project.loan.ltv * 10) / 10) + '%' : '' }}
          <c-tooltip
              text="Коэффициент «кредит/залог» (LTV) — отношение суммы запрашиваемого кредита к стоимости имущества, предоставляемого заемщиком в залог."
              class="ml-5"/>
        </dd>
        <dt>Тип графика:</dt>
        <dd>{{ paymentScheduleType }}</dd>
        <dt>Тип сделки:</dt>
        <dd>{{ project.type === ProjectType.PROJECT ? 'Проект' : 'Займ' }}</dd>
        <div v-if="projectDividendFee">
          <dt>Комиссия за управление залогом:</dt>
          <dd>{{ projectDividendFee }} %</dd>
        </div>
      </dl>
    </div>
    <div v-if="isFundraising" class="my-5 block-invest">
      <div v-if="lenderNotVerified" class="card border-info">
        <div class="card-body">
          Для возможности инвестировать необходимо
          <router-link :to="{ name: 'authorization' }" class="font-weight-bold">пройти авторизацию</router-link>
        </div>
      </div>
      <div class="card border-info">
        <div class="card-body">
          Для инвестирования необходимо подписать документы с помощью Усиленной Цифровой Подписи на токене для
          Росреестра.
          Для получения подробностей просим написать на почту <a href="mailto:support@lendly.ru">support@lendly.ru</a>
        </div>
      </div>
      <div v-if="investmentNotAllowed" class="card border-info">
        <div class="card-body">
          В Вашем аккаунте отключена возможность инвестирования.<br>
          Для более детальной информации обратитесь в службу технической поддержки:
          <a href="mailto:support@lendly.ru" class="font-weight-bold">support@lendly.ru</a>
        </div>
      </div>
      <div v-if="requiresNotary"
           class="card border-info">
        <div class="card-body">
          <p>
            Для инвестирования на первичном рынке необходимо предоставить нотариальную доверенность с
            тождественным заверением подписи нотариуса, выданную на имя ООО Лендли, как управляющего залогом, на
            заключение договора ипотеки в качестве залогодержателя, его государственную регистрацию и
            последующее управление залогом.
          </p>
          <p>
            Скан доверенности в формате pdf и электронный формат тождественности нотариальной подписи в формате
            sig просим отправить на почту
            <a href="mailto:support@lendly.ru">support@lendly.ru</a>
          </p>
          <a href="https://cdn.lendly.ru/documents/doverenost_template.pdf" download
             class="btn btn-success mt-2">
            Шаблон доверенности
          </a>
        </div>
      </div>
      <div v-if="rulesNotConfirmed" class="card border-error">
          <div class="card-body rules-card">
            <img class="warning-img" src="@/assets/img/attention.png" alt="!">
            <div>
              Пожалуйста
              <router-link :to="{ name: 'rules-confirmation' }" class="text-nowrap">ознакомьтесь с новыми
                правилами
                платформы
              </router-link>
            </div>
          </div>
      </div>
      <div v-else-if="pendingRegistration" class="card border-info">
        <div class="card-body">
          Минимальная сумма набрана, идет регистрация залога
        </div>
      </div>
      <div v-else-if="insufficientFunds"
           class="card border-info">
        <div v-if="!balanceEnough" class="card-body">
          Недостаточно средств на балансе для совершения инвестиции.<br>
          Минимальная сумма инвестиции:
          <money :money="minInvestmentSum"/>
          <br>
        </div>
        <div v-else-if="investmentLimitReached" class="card-body">
          Вы достигли лимита для инвестирования в год.<br>
          Чтобы увеличить этот лимит обратитесь в службу технической поддержки:
          <a href="mailto:support@lendly.ru" class="font-weight-bold">support@lendly.ru</a>
        </div>
        <div v-else-if="notEnoughInvestmentLimit" class="card-body">
          <div class="card-body">
            Минимальная сумма набрана, идет регистрация залога
          </div>
        </div>
        <div v-else class="card-body">
          Недостаточный лимит для покупки, осталось:
          <money :money="investmentAvailableSum"/>
          <br>
          Чтобы увеличить этот лимит обратитесь в службу технической поддержки:
          <a href="mailto:support@lendly.ru" class="font-weight-bold">support@lendly.ru</a>
        </div>
      </div>
      <div v-if="lenderReservation.length !== 0" class="card border-info">
        <div class="card-body">
          Зарезервированная сумма:
          <money :money="lenderReservation[0].reservationRemain"/>
        </div>
        <div class="card-body">
          <b>Обратите внимание:</b> для успешной инвестиции в проект, необходимо осуществить единовременный
          платеж на всю зарезервированную сумму.
          В противном случае, ваша резервация будет аннулирована.
          Пожалуйста, удостоверьтесь, что сумма вашей инвестиции соответствует зарезервированной, чтобы избежать
          утраты резервации.
        </div>
      </div>
      <form
          v-if="canInvest"
          @submit="invest">
        <div class="form-group">
          <div class="input-group">
            <input-money v-model="investmentAmount"
                         @keyup="validateInvestmentAmount"
                         @blur="validateInvestmentAmount"
                         :min="minInvestment"
                         :max="investmentAvailableAmount"
                         class="form-control"
                         placeholder="Введите сумму"
                         required/>
            <div class="input-group-append">
              <button type="submit" class="btn btn-success">Инвестировать</button>
            </div>
          </div>
          <input-errors :errors="errors.investmentAmount"/>
          <div class="form-group mt-2">
            <span class="text-muted">Доступно:</span>
            <money :money="investmentAvailableSum" class="ml-1"/>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import PublicLink from "@/components/common/components/PublicLink.vue";
import DateFormat from "@/components/common/components/DateFormat.vue";
import CTooltip from "@/components/common/components/CTooltip.vue";
import {TooltipTextPosition} from "@/utils/api-client/enums/TooltipTextPosition";
import {InterestRateType} from "@/utils/api-client/enums/InterestRateType";
import Money from "@/components/common/components/Money.vue";
import InputMoney from "@/components/common/components/InputMoney.vue";
import InputErrors from "@/components/common/components/InputErrors.vue";
import {Money as ApiMoney, PaymentScheduleType, ProjectStatus, TransactionType} from "@/utils/api-client";
import {ProjectType} from "@/utils/api-client/enums/ProjectType";
import {formatAmount} from "@/components/common";
import {createInvestmentManager} from "@/utils/di";

export default {
  name: "DetailedProjectInvestment",
  components: {InputErrors, InputMoney, Money, CTooltip, DateFormat, PublicLink},
  props: {
    project: {
      type: Object,
      required: true
    },
    profile: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      investmentAmount: null,
      errors: {},
      processing: false,
      feeSettings: null
    };
  },
  computed: {
    ProjectType() {
      return ProjectType
    },
    InterestRateType() {
      return InterestRateType
    },
    TooltipTextPosition() {
      return TooltipTextPosition
    },
    paymentScheduleType() {
      const paymentScheduleTypes = {
        [PaymentScheduleType.MONTHLY_PAYMENTS]: "Проценты ежемесячно",
        [PaymentScheduleType.QUARTERLY_PAYMENTS]: "Ежеквартальный",
        [PaymentScheduleType.PAYMENT_AT_THE_END]: "Оплата в конце"
      };
      if (this.project.loan && this.project.loan.paymentSchedule && paymentScheduleTypes[this.project.loan.paymentSchedule.scheduleType]) {
        return paymentScheduleTypes[this.project.loan.paymentSchedule.scheduleType];
      }
    },
    projectDividendFee() {
      let fee;
      if (this.feeSettings) {
        this.feeSettings.items.map(item => {
          if (item.transactionType === TransactionType.DIVIDEND_INTEREST_FEE) {
            fee = item.percent;
          }
        })
      }
      return fee;
    },
    isFundraising() {
      return this.project.status === ProjectStatus.FUND_RAISING;
    },
    lenderReservation() {
      return this.project.reservations.filter((item) => item.lenderUuid === this.profile.uuid);
    },
    isRegistration() {
      if (this.project.loan.collateralRegistrationSum && this.project.loan.collateralRegistrationSum.amount) {
        return (this.project.loan.loanSum.amount - this.project.loan.investedSum.amount) <= this.project.loan.collateralRegistrationSum.amount;
      }
      return false;
    },
    investmentAvailable() {
      return this.isFundraising && this.investmentAvailableAmount >= this.minInvestment;
    },
    balanceEnough() {
      return this.profile.balance.amount >= this.minInvestmentSum.amount;
    },
    minInvestmentSum() {
      return new ApiMoney(this.minInvestment * 100, this.project.loan.loanSum.currencyCode);
    },
    minInvestment() {
      return this.project.loan.minInvestment.amount / 100;
    },
    investmentAvailableSum() {
      let amount = 0;
      if (this.project.loan && this.project.loan.loanSum && this.project.loan.investedSum) {
        amount = this.project.loan.loanSum.amount - this.project.loan.investedSum.amount;
      }

      if (!this.profile.maxInvestmentAllow) {
        if (this.project.loan.collateralRegistrationSum && this.project.loan.collateralRegistrationSum.amount) {
          if (amount > this.project.loan.collateralRegistrationSum.amount) {
            amount -= this.project.loan.collateralRegistrationSum.amount;
          }
        }
      }

      if (this.project.reservations.length !== 0) {
        this.project.reservations.forEach((item) => {
          if (item.lenderUuid !== this.profile.uuid) {
            amount -= item.reservationRemain.amount;
          }
        })
      }

      if (amount > this.profile.balance.amount) {
        amount = this.profile.balance.amount;
      }

      if (this.profile.investmentLimit && amount > this.profile.investmentLimit.amount) {
        amount = this.profile.investmentLimit.amount;
      }

      return new ApiMoney(amount, this.project.loan.loanSum.currencyCode);
    },
    investmentAvailableAmount() {
      return Math.floor(this.investmentAvailableSum.amount / 100)
    },

    lenderNotVerified() {
      return !this.profile.lenderVerified;
    },
    investmentNotAllowed() {
      return this.profile.lenderVerified && !this.profile.investmentAllow;
    },
    requiresNotary() {
      return this.profile.lenderVerified && !this.profile.primaryMarketAllow && !this.project.loan.noProxy;
    },
    rulesNotConfirmed() {
      return this.profile.lenderVerified && !this.profile.isRulesConfirmed;
    },
    pendingRegistration() {
      return this.isRegistration && !this.profile.maxInvestmentAllow;
    },
    insufficientFunds() {
      return this.profile.lenderVerified && (this.investmentAvailable === false || this.balanceEnough === false);
    },
    investmentLimitReached() {
      return this.profile.investmentLimit?.amount === 0;
    },
    notEnoughInvestmentLimit() {
      return this.investmentAvailableAmount < this.minInvestment;
    },
    canInvest() {
      return this.profile.lenderVerified && this.profile.investmentAllow &&
          this.investmentAvailable && this.balanceEnough &&
          (this.profile.primaryMarketAllow || this.project.loan.noProxy) &&
          this.profile.isRulesConfirmed && (!this.isRegistration || this.profile.maxInvestmentAllow);
    },
  },
  methods: {
    validateInvestmentAmount() {
      const errors = [];
      if (this.investmentAmount < this.minInvestment) {
        errors.push(`Минимальная сумма инвестиции ${formatAmount(this.minInvestment)} руб.`)
      }
      if (this.investmentAmount > this.investmentAvailableAmount) {
        errors.push(`Максимальная сумма инвестиции ${formatAmount(this.investmentAvailableAmount)} руб.`)
      }
      this.errors.investmentAmount = errors;
      return errors.length === 0;
    },
    async invest(e) {
      e.preventDefault();
      if (this.validateInvestmentAmount()) {
        this.processing = true;
        try {
          const manager = await createInvestmentManager();
          const result = await manager.createConfirmation(
              this.project.uuid,
              new ApiMoney(this.investmentAmount * 100, this.profile.balance.currencyCode)
          );
          await this.$router.push({
            name: "investmentConfirmation",
            params: {uuid: this.project.uuid, confirmationUuid: result.uuid, step: 1}
          });
        } finally {
          this.processing = false;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.block-general {
  a.link-mortgage-rank {
    color: #6c757d;
    text-decoration: underline;
  }

  a.link-mortgage-rank:hover {
    text-decoration: none;
  }
}
.rules-card {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.warning-img {
  width: 2.5rem;
}

.block-invest {
  .form-group {
    width: 25rem;
    max-width: 100%;
  }
}
</style>
