<template>
  <div class="col-12 col-lg-6">
    <div class="text-muted">
      оферта ID {{ project.paymentCode }}
    </div>

    <div id="project-photos" class="carousel slide mt-3">
      <div class="carousel-caption">
        <span class="badge badge-secondary">{{ projectStatus }}</span>
      </div>
      <ol v-if="project.general.photoUrls.length > 1" class="carousel-indicators">
        <li v-for="(_, index) in project.general.photoUrls"
            :key="index"
            data-target="#project-photos"
            :data-slide-to="index"
            :class="{ active: index === 0 }"
        ></li>
      </ol>
      <div class="carousel-inner">
        <div v-for="(photoUrl, index) in project.general.photoUrls"
             :key="index"
             class="carousel-item"
             :class="{ active: index === 0 }"
             :style="`background-image: url('${photoUrl}');`">
        </div>
      </div>
      <a v-if="project.general.photoUrls.length > 1"
         class="carousel-control-prev"
         href="#project-photos"
         role="button"
         data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a v-if="project.general.photoUrls.length > 1"
         class="carousel-control-next"
         href="#project-photos"
         role="button"
         data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>

    <div class="mt-5">
      <div class="clearfix">
        <div class="float-left">
          <span class="text-muted money-caption">Набрано</span>
          <money :money="project.loan.investedSum"/>
        </div>
        <div class="float-right text-right">
          <span class="text-muted money-caption">Сумма сбора</span>
          <money :money="project.loan.loanSum"/>
        </div>
      </div>
      <invest-progress-bar
          :loan-sum="project.loan.loanSum"
          :invested-sum="project.loan.investedSum"
          :reservation-sum="reservationSum"
          :progress-bar-height="20"/>

      <div class="mb-2">
        <div class="clearfix">
          <div class="float-left">
            <span class="text-muted money-caption">Сумма в обработке</span>
            <money :money="reservationSum"/>
          </div>
          <div class="float-right text-right">
            <span class="text-muted money-caption">Свободный остаток</span>
            <money :money="freeSum"/>
          </div>
        </div>
      </div>

      <div class="clearfix">
        <div class="float-left">
          <span class="text-muted money-caption">Инвесторов</span>
          {{ project.loan.countInvestors }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Money from "@/components/common/components/Money.vue";
import InvestProgressBar from "@/components/common/components/InvestProgressBar.vue";
import {Money as ApiMoney} from "@/utils/api-client";

export default {
  name: "DetailedProjectOverview",
  components: {InvestProgressBar, Money},
  props: {
    project: {
      type: Object,
      required: true
    },
    projectStatus: {
      type: String,
      required: true
    },
  },
  computed: {
    reservationSum() {
      if (!this.project || !this.project.loan) return new ApiMoney(0, "RUB");

      const collateralRegistrationSum = this.project.loan.collateralRegistrationSum
          ? this.project.loan.collateralRegistrationSum.amount
          : 0;

      const reservationAmount = this.project.reservations.reduce((total, el) => {
        return total + el.reservationRemain.amount;
      }, 0);

      return new ApiMoney(reservationAmount + collateralRegistrationSum, this.project.loan.loanSum.currencyCode);
    },
    freeSum() {
      if (!this.project || !this.project.loan) return new ApiMoney(0, "RUB");

      const remainingAmount =
          this.project.loan.loanSum.amount - this.reservationSum.amount - this.project.loan.investedSum.amount;

      return new ApiMoney(Math.max(remainingAmount, 0), this.project.loan.loanSum.currencyCode);
    }
  }
};
</script>

<style lang="scss" scoped>
#project-photos {
  position: relative;
  width: 100%;

  .carousel-caption {
    position: absolute;
    bottom: -2rem;

    .badge-secondary {
      background: #252c54;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .carousel-inner {
    .carousel-item {
      height: 20rem;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .carousel-indicators li {
    color: #21e58a;
    background-color: #21e58a;
  }

  .carousel-control-prev,
  .carousel-control-next {
    color: #21e58a;
  }
}
.text-muted {
  font-size: 0.9rem;
}
.money-caption {
  margin-right: 0.5rem;
}
</style>
