<template>
  <div v-if="paymentSchedule" class="tab-pane fade" id="payment-schedule" role="tabpanel"
       aria-labelledby="payment-schedule-tab">
    <div class="block-payment-schedule">
      <div class="row block-payment-schedule__row">
        <div class="col-xl-2 col-lg-2 col-md-3 small text-muted">
          Дата платежа
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 small text-muted">
          Выплата процентов
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 small text-muted">
          Выплата основного долга
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 small text-muted">
          Дата оплаты
        </div>
      </div>
      <div v-for="(item, index) in paymentSchedule.payments" :key="index"
           class="row block-payment-schedule__row"
           :class="getPaymentStatusClass(item)">
        <div class="col-xl-2 col-lg-2 col-md-3">
          <date-format :date="item.paymentDate"/>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3">
          <money :money="item.interestPayment"/>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3">
          <money :money="item.principalRepayment"/>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3">
          <span v-if="item.paidAt">
            <date-format :date="item.paidAt"/>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DateFormat from "@/components/common/components/DateFormat.vue";
import Money from "@/components/common/components/Money.vue";

export default {
  name: "PaymentSchedule",
  components: {Money, DateFormat},
  props: {
    paymentSchedule: {
      type: Object,
      required: true,
      validator: (value) => {
        return Array.isArray(value.payments);
      }
    },
    projectStatus: {
      type: String,
      required: true
    }
  },
  methods: {
    isPaymentOverdue(payment) {
      if (this.projectStatus === "PAID") return false;

      const paymentDate = moment(payment.paymentDate).format('YYYY-MM-DD');
      let paidAt = payment.paidAt ? moment(payment.paidAt).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');

      paidAt = moment(paidAt).subtract(3, 'days');
      return paidAt.isAfter(paymentDate);
    },
    getPaymentStatusClass(item) {
      if (this.isPaymentOverdue(item) && item.paid) return 'payment-paid-late';
      if (!this.isPaymentOverdue(item) && item.paid) return 'payment-paid';
      if (this.isPaymentOverdue(item)) return 'payment-overdue';
      return '';
    }
  }
};
</script>

<style lang="scss" scoped>
.block-payment-schedule {
  overflow-x: auto;

  &__row {
    flex-wrap: nowrap;
  }
}

.payment-paid {
  background-color: #d2f8d2;
}

.payment-paid-late {
  background-color: #ffdb9a;
}

.payment-overdue {
  background-color: #ffb6c1;
}
</style>