<template>
  <div class="mt-3 mt-lg-4 block-additional">
    <ul class="nav nav-tabs mb-3" role="tablist">
      <li v-if="isFundRaising && secondaryMarket" class="nav-item" role="presentation">
        <a class="nav-link active"
           id="offers-tab"
           data-toggle="tab"
           href="#offers"
           role="tab"
           aria-controls="offers"
           aria-selected="true">
          Вторичный рынок
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a :class="{ active: !isFundRaising || !secondaryMarket }" class="nav-link"
           id="common-tab"
           data-toggle="tab"
           href="#common"
           role="tab"
           aria-controls="common"
           aria-selected="false">
          Общая информация
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link"
           id="collaterals-tab"
           data-toggle="tab"
           href="#collaterals"
           role="tab"
           aria-controls="collaterals"
           aria-selected="false">
          Обеспечение
        </a>
      </li>
      <li v-if="project.financials" class="nav-item" role="presentation">
        <a class="nav-link"
           id="financials-tab"
           data-toggle="tab"
           href="#financials"
           role="tab"
           aria-controls="financials"
           aria-selected="false">
          Финансовая <span class="d-none d-lg-inline-block">информация</span>
        </a>
      </li>
      <li v-if="project.documents" class="nav-item" role="presentation">
        <a class="nav-link"
           id="documents-tab"
           data-toggle="tab"
           href="#documents"
           role="tab"
           aria-controls="documents"
           aria-selected="false">
          Документы
        </a>
      </li>
      <li v-if="project.documents" class="nav-item" role="presentation">
        <a class="nav-link"
           id="payment-schedule-tab"
           data-toggle="tab"
           href="#payment-schedule"
           role="tab"
           aria-controls="payment-schedule"
           aria-selected="false">
          График платежей
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div v-if="isFundRaising && secondaryMarket" class="tab-pane fade show active" id="offers" role="tabpanel"
           aria-labelledby="offers-tab">
        <project-offers :project-uuid="project.uuid" :buy="buy"/>
      </div>
      <DetailedProjectCommonInfo
          :project="project"
          :secondaryMarket="secondaryMarket"
          :isFundRaising="isFundRaising"
          :siteUrlHost="siteUrlHost"/>
      <DetailedProjectCollaterals :project="project"/>
      <DetailedProjectFinancials :financials="project.financials"/>
      <DetailedProjectDocuments :documents="project.documents"/>
      <PaymentSchedule :paymentSchedule="project.loan.paymentSchedule" :project-status="project.status"/>
    </div>
  </div>
</template>

<script>

import DetailedProjectCommonInfo from "@/components/common/components/DetailedProjectCommonInfo.vue";
import DetailedProjectCollaterals from "@/components/common/components/DetailedProjectCollaterals.vue";
import DetailedProjectFinancials from "@/components/common/components/DetailedProjectFinancials.vue";
import DetailedProjectDocuments from "@/components/common/components/DetailedProjectDocuments.vue";
import PaymentSchedule from "@/components/common/components/PaymentSchedule.vue";
import ProjectOffers from "@/components/common/components/ProjectOffers.vue";

export default {
  components: {
    ProjectOffers,
    DetailedProjectCommonInfo,
    DetailedProjectCollaterals,
    DetailedProjectFinancials,
    DetailedProjectDocuments,
    PaymentSchedule
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    isFundRaising: {
      type: Boolean,
      required: true
    },
    secondaryMarket: {
      type: Boolean,
      required: true
    },
    siteUrlHost: {
      type: String,
      required: true
    },
    buy: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss">
.block-additional {
  min-height: 15rem;

  .h6 {
    margin-top: 1.5rem;
  }

  dl {
    dt {
      flex: 0 0 35%;
      max-width: 35%;
    }

    dd {
      flex: 0 0 60%;
      max-width: 35rem;

      a {
        float: right;
      }
    }
  }

  p {
    margin-bottom: 0.25em;
  }

  .nav-tabs {
    border: none;

    .nav-link,
    .nav-link:hover,
    .nav-link:focus,
    .nav-link.active {
      position: relative;
      border: none;
      background: none;
    }

    .nav-link {
      color: #000;
      margin-left: 0;
      margin-right: 0;

      @media (min-width: 768px) { // Bootstrap MD
        margin-left: 0.25rem;
        margin-right: 0.25rem;
      }

      @media (min-width: 1040px) {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }

      @media (min-width: 1140px) {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

    .nav-link.active {
      color: #21e58a;
    }

    .nav-link.active:before {
      content: "";
      background: #21e58a;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0.2rem;
      width: 100%;
    }
  }
}
</style>
