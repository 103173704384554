<template>
  <div v-if="project.collaterals" class="tab-pane fade" id="collaterals" role="tabpanel" aria-labelledby="collaterals-tab">
    <div class="block-collaterals">
      <div class="h6">Залог недвижимости</div>
      <dl v-for="collateral in project.collaterals" :key="collateral.id">
        <dt>Тип объекта</dt>
        <dd>{{ project.loan.objectType }}</dd>
        <dt>Место расположения объекта</dt>
        <dd>{{ collateral.address }}</dd>
        <dt>Площадь объекта</dt>
        <dd>{{ collateral.square }} м<sup>2</sup></dd>
        <dt>Дата приобретения</dt>
        <dd>
          <date-format :date="collateral.purchaseDate"/>
        </dd>
        <dt>Основание приобретения</dt>
        <dd>{{ collateral.purchaseReason }}</dd>
        <dt>Оценочная стоимость</dt>
        <dd>
          <money :money="collateral.cost"/>
        </dd>
        <dt>LTV при целевой сумме</dt>
        <dd>{{ collateral.ltv }}</dd>
        <dt v-if="collateral.comment">Комментарий по залогу</dt>
        <dd v-if="collateral.comment">{{ collateral.comment }}</dd>
      </dl>
      <div class="h6">Поручительство</div>
      <div v-if="project.guarantors.length === 0">
        <i>Нет</i>
      </div>
      <dl v-for="guarantor in project.guarantors" :key="guarantor.id">
        <dt>{{ guarantor.fullName }}</dt>
        <dd>Доля в УК: {{ guarantor.share ? (Math.floor(guarantor.share * 10) / 10) + "%" : "-" }}</dd>
      </dl>
    </div>
  </div>
</template>

<script>
import Money from "@/components/common/components/Money.vue";
import DateFormat from "@/components/common/components/DateFormat.vue";

export default {
  name: "DetailedProjectCollaterals",
  components: {DateFormat, Money},
  props: {
    project: {
      type: Object,
      required: true
    }
  }
};
</script>
