<template>
  <div v-if="!show">
    <div class="modal show modal-rules"
         tabindex="-1"
    >
      <div class="overflow-block container-xl" ></div>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <p>
              Для инвестирования на первичном рынке необходимо предоставить нотариальную доверенность с
              тождественным заверением подписи нотариуса, выданную на имя ООО Лендли, как управляющего залогом, на
              заключение договора ипотеки в качестве залогодержателя, его государственную регистрацию и
              последующее управление залогом.
            </p>
            <p>
              Скан доверенности в формате pdf и электронный формат тождественности нотариальной подписи в формате
              sig просим отправить на почту
              <a href="mailto:support@lendly.ru">support@lendly.ru</a>
            </p>
            <a href="https://cdn.lendly.ru/documents/doverenost_template.pdf" download
               class="btn btn-success mt-2">
              Шаблон доверенности
            </a>
          </div>
        </div>
      </div>
    </div >
  </div>
</template>

<script>
import {PublicLink} from "@/components/common";

export default {
  components:{
    PublicLink
  },
  name: "ModalDialog",
  inject: ["profile"],
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      }
    }
  }
}
</script>

<style scoped>

.overflow-block {
  background-color: #6c757d;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
}

.modal-rules {
  z-index: 20;
  position: absolute;
  text-align: center;
  vertical-align: center;
  margin: auto;
  left: 0;
  right: 0;
  display: inline;
}

</style>