<template>
  <div v-if="documents" class="tab-pane fade" id="documents" role="tabpanel" aria-labelledby="documents-tab">
    <div class="block-documents">
      <a v-for="document in documents" :key="document.id" :href="document.url" target="_blank">
        {{ document.name }}<br>
        <img alt="PDF" src="@/assets/img/pdf_icon.png">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailedProjectDocuments",
  props: {
    documents: {
      type: Array,
      required: true,
      validator: (docs) => docs.every(doc => typeof doc.id === 'number' && typeof doc.name === 'string' && typeof doc.url === 'string')
    }
  }
};
</script>

<style lang="scss" scoped>
.block-documents {
  a {
    display: inline-block;
    text-align: center;
    margin: 0 0.5rem;

    img {
      height: 6.75rem;
      width: auto;
    }
  }
}
</style>
