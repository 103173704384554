<template>
  <div class="detailed-project">
    <div :class="{ card }" class="mb-3">
      <div v-if="!project.uuid" class="text-center p-5"><i>Загрузка...</i></div>
      <div v-if="project.uuid" :class="{ 'card-body': card }">
        <div v-if="backButton" class="form-group">
          <button @click="$router.back()" class="btn btn-link" type="button">
            <i class="bi-arrow-return-left"></i>
            Обратно
          </button>
        </div>
        <rules-confirmed-modal :show="profile.primaryMarketAllow"/>
        <div class="row">
          <DetailedProjectOverview
              :project="project"
              :projectStatus="projectStatus"
          />
          <DetailedProjectInvestment :profile="profile"
                                     :project="project"/>
        </div>
        <DetailedProjectTabs
            :project="project"
            :isFundRaising="isFundRaising"
            :secondaryMarket="secondaryMarket"
            :siteUrlHost="siteUrlHost"
            :buy="buy"
        />
      </div>
    </div>
    <processing :show="processing"/>
  </div>
</template>

<script>
import {RulesConfirmedModal} from "@/components/common";
import {ProjectStatus} from "@/utils/api-client";
import {createFeeSettingsProvider, createProjectProvider} from "@/utils/di";
import moment from "moment";
import CTooltip from "./CTooltip";
import DateFormat from "./DateFormat";
import InputErrors from "./InputErrors";
import InputMoney from "./InputMoney";
import Money from "./Money";
import Processing from "./Processing";
import ProjectOffers from "./ProjectOffers";
import PublicLink from "./PublicLink";
import RouterLink from "vue-router"
import InvestProgressBar from "@/components/common/components/InvestProgressBar.vue";
import DetailedProjectTabs from "@/components/common/components/DetailedProjectTabs.vue";
import DetailedProjectOverview from "@/components/common/components/DetailedProjectOverview.vue";
import DetailedProjectInvestment from "@/components/common/components/DetailedProjectInvestment.vue";

export default {
  name: "DetailedProject",
  components: {
    DetailedProjectInvestment,
    DetailedProjectOverview,
    DetailedProjectTabs,
    InvestProgressBar,
    RulesConfirmedModal,
    PublicLink,
    CTooltip,
    DateFormat,
    InputErrors,
    InputMoney,
    Money,
    Processing,
    ProjectOffers,
    RouterLink,
  },
  inject: ["profile"],
  props: {
    uuid: {
      type: String,
      required: true
    },
    backButton: {
      type: Boolean,
      default: false
    },
    buy: {
      type: Boolean,
      default: true
    },
    card: {
      type: Boolean,
      default: true
    },
    secondaryMarket: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      project: {},
      investmentAmount: null,
      errors: {},
      processing: false,
      feeSettings: null
    };
  },
  async mounted() {
    const provider = await createProjectProvider();
    this.project = await provider.getOne(this.uuid);
    const feeSettingsProvider = await createFeeSettingsProvider();
    this.feeSettings = await feeSettingsProvider.getList({projectUuids: [await this.project.uuid]});
  },
  computed: {
    isFundRaising() {
      return this.project.status === ProjectStatus.FUND_RAISING;
    },
    projectStatus() {
      if (this.project.status) {
        if (this.project.status === ProjectStatus.REVIEW || this.project.status === ProjectStatus.FINAL_REVIEW) {
          return "На рассмотрении";
        } else if (this.project.status === ProjectStatus.REVIEW_APPROVED) {
          return "Заполнение информации";
        } else if (this.project.status === ProjectStatus.REVIEW_REJECTED || this.project.status === ProjectStatus.FINAL_REVIEW_REJECTED) {
          return "Отклонено";
        } else if (this.project.status === ProjectStatus.FINAL_REVIEW_APPROVED) {
          return "Публикация проекта";
        } else if (this.project.status === ProjectStatus.FUND_RAISING) {
          let diff = moment(this.project.loan.maturityDate).diff(new Date());
          if (isNaN(diff) || diff < 0) {
            diff = 0;
          }
          const daysLeft = Math.ceil(diff / (60 * 60 * 24 * 1000));
          const reminder = daysLeft % 10;
          const leftCaptions = {1: "Остался"};
          const leftCaption = leftCaptions[reminder] || "Осталось";
          const dayCaptions = {1: "день", 2: "дня", 3: "дня", 4: "дня"};
          const dayCaption = dayCaptions[reminder] || "дней";
          return `${leftCaption} ${daysLeft} ${dayCaption}`;
        } else if (this.project.status === ProjectStatus.FINISHED || this.project.status === ProjectStatus.LOAN_PAYMENT) {
          return "Проинвестирован";
        } else {
          return "Завершен";
        }
      }
    },
    siteUrlHost() {
      if (this.project.general.siteUri) {
        try {
          return new URL(this.project.general.siteUri).hostname;
        } catch (err) {
          console.log(err);
        }
      }
    },
    primaryMarketAllow() {
      return this.profile.primaryMarketAllow;
    },
  }
}
</script>